
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';
import config from "../config.js";




class FDVDocumentation extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
        };
    }

    componentDidMount() {

        //Load schema data
        fetch(config.server.connection.url + "/api/BoligrorleggerenCustomerController/GetFDVDocumentation?jobId=" + this.props.jobId, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    render() {

        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>
                                <div id="printDiv">
                                    {/* ---------------- FDV DOKUMENTASJON ----------------  */}
                                    <CardHeader>
                                        <CardTitle tag="h4">FDV Dokumentasjon</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table style={{ border: 'none' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>1. PRODUKTBESKRIVELSE</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Produktbeskrivelse}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Elnummer:</b></td>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Elnummer}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Dimensjon:</b></td>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Dimensjon}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Farge:</b></td>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Farge}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Overflatebehandling:</b></td>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Overflatebehandling}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Øvrige opplysninger:</b></td>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.OvrigeOpplysninger}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <br></br>

                                        <Table  style={{ border: 'none' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>2. ANVISNINGER FOR DRIFT OF VEDLIKEHOLD</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Rengjøring og rengjøringsmetoder for overflateprodukter</b></td>
                                                </tr>
                                                <tr>
                                                    <td  style={{ border: 'none' }} >{schemaDataObject.InstruksRengjoring}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Ettersyn/ Kontroll</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Ettersyn}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Vedlikeholdsinstruks og intervall</b></td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Vedlikeholdsinstruks}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        <br></br>

                                        <Table style={{ border: 'none' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>3. DRIFTSTEKNISKE OPPLYSNINGER</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Antatt teknisk levetid uten utskiftninger er mer enn {schemaDataObject.Levetid} år </b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Garanti og vilkår</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Garanti}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Fuktbestandighet</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Fuktbestandighet}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Renholdsvennlighet</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Renholdsvennlighet}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Øvrige opplysninger</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.OvrigeOpplysningerDrift}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        <Table  style={{ border: 'none' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>4. TEKNISK SERVICE</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Produsent/ importør</b></td>
                                                </tr>
                                                <tr>
                                                    <td  style={{ border: 'none' }} >{schemaDataObject.Produsent}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Postadresse</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Postadresse}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Postnummer og poststed</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Postnummer} - {schemaDataObject.Poststed}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Telefon</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Telefon}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Epost</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Epost}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}><b>Internett</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>{schemaDataObject.Internett}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                       

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />

                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default FDVDocumentation;