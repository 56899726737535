
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';
import config from "../config.js";



class Fakturagrunnlag extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            error: false,
            fakturaDataObject: {},
            productArray: [],
        };
    }

    componentDidMount() {
        //Load schema data
        fetch(config.server.connection.url + "/api/BoligrorleggerenCustomerController/InvoiceBasis", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID"),
                'InvoiceID': this.props.invoiceId,
                'JobID': this.props.jobId
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loading: false,
                        fakturaDataObject: result,
                        productArray: result.products
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }

    render() {

        const tdColumn1Style = {
            width: "60%",
            borderTop: "0px",

        };

        const tdColumn2Style = {
            borderTop: "0px",
            padding: "4px",
            textAlign: "right",

        };


        const { error, loading, fakturaDataObject, productArray } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>
                                <div id="printDiv">

                                    <CardHeader>
                                        <CardTitle tag="h4">Fakturagrunnlag</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }}><b>Fakturagrunnlagsnummer:</b></td>
                                                    <td style={{ textAlign: "right" }}>{fakturaDataObject.id}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Kundenummer:</b></td>
                                                    <td style={tdColumn2Style} >{fakturaDataObject.kundeNr}</td>

                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Dato:</b></td>
                                                    <td style={tdColumn2Style} >{fakturaDataObject.dato}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Montør:</b></td>
                                                    <td style={tdColumn2Style} >{fakturaDataObject.dato}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Arbeid start:</b></td>
                                                    <td style={tdColumn2Style} >{fakturaDataObject.jobStart}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Arbeid slutt:</b></td>
                                                    <td style={tdColumn2Style} >{fakturaDataObject.jobStop}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- UTFØRT OPPDRAG ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Utført oppdrag</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Steder:</b></td>
                                                    <td><b>Utført oppdrag</b></td>
                                                </tr>
                                                {(() => {
                                                        if (this.state.fakturaDataObject.steder1) 
                                                        { 
                                                            return(
                                                            <tr>
                                                                <td >{this.state.fakturaDataObject.steder1}</td>
                                                                <td >{this.state.fakturaDataObject.desc1}</td>
                                                            </tr>
                                                            )
                                                        }
                                                        else 
                                                        { return }
                                                    }
                                                    )()}
                                                {(() => {
                                                    if (this.state.fakturaDataObject.steder2) 
                                                    { 
                                                        return(
                                                        <tr>
                                                            <td >{this.state.fakturaDataObject.steder2}</td>
                                                            <td >{this.state.fakturaDataObject.desc2}</td>
                                                        </tr>
                                                        )
                                                    }
                                                    else 
                                                    { return }
                                                }
                                                )()}
                                                  {(() => {
                                                    if (this.state.fakturaDataObject.steder3) 
                                                    { 
                                                        return(
                                                        <tr>
                                                            <td >{this.state.fakturaDataObject.steder3}</td>
                                                            <td >{this.state.fakturaDataObject.desc3}</td>
                                                        </tr>
                                                        )
                                                    }
                                                    else 
                                                    { return }
                                                }
                                                )()}
                                                  {(() => {
                                                    if (this.state.fakturaDataObject.steder4) 
                                                    { 
                                                        return(
                                                        <tr>
                                                            <td >{this.state.fakturaDataObject.steder4}</td>
                                                            <td >{this.state.fakturaDataObject.desc4}</td>
                                                        </tr>
                                                        )
                                                    }
                                                    else 
                                                    { return }
                                                }
                                                )()}
                                                  {(() => {
                                                    if (this.state.fakturaDataObject.steder5) 
                                                    { 
                                                        return(
                                                        <tr>
                                                            <td >{this.state.fakturaDataObject.steder5}</td>
                                                            <td >{this.state.fakturaDataObject.desc5}</td>
                                                        </tr>
                                                        )
                                                    }
                                                    else 
                                                    { return }
                                                }
                                                )()}
                                                

                                            </tbody>
                                        </Table>

                                     
                                        {/* ---------------- PRODUKTER ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Produkter:</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>Beskrivelse</td>
                                                    <td>Antall</td>
                                                    <td style={{ textAlign: "right"}}>Total pris (eks. mva.)</td>
                                                </tr>
                                                {(() => {
                                                     let table = []
                                                      for (var i = 0; i < productArray.length ; i++) {
                                                        let children = []
                                                        children.push(<td key={Math.random()}> {productArray[i].desc} {productArray[i].rabatt !='0.00' && productArray[i].salesprice !='0.00' ? 
                                                            `(${productArray[i].rabatt}% rabatt)` :
                                                            ''
                                                        }</td>)
                                                        children.push(<td key={Math.random()}> {productArray[i].qty}</td>)
                                                        children.push(<td  style={{ textAlign: "right"}} key={Math.random()}>{((parseFloat(productArray[i].salesprice))* parseFloat(productArray[i].qty)).toFixed(2)}</td>)
                                                        table.push(<tr key={Math.random()}>{children}</tr>)
                                                    } 
                                                    return(table)
                                                }
                                                )()}
                                            </tbody>
                                        </Table>

                                                {/* ---------------- TOTALT ----------------  */}
                                                <CardHeader>
                                            <CardTitle><b>Total sum:</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                
                                                <tr>
                                                    <td>Sum arbeid:</td><td style={{ textAlign: "right"}}>{(parseFloat(fakturaDataObject.arbeidSum)).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sum materiell:</td><td style={{ textAlign: "right"}}>{fakturaDataObject.materiellSum}</td>
                                                </tr>
                                                <tr>
                                                    <td >Oppstartskostnad:</td><td style={{ textAlign: "right"}}>{fakturaDataObject.fremmoteSum}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mva:</td><td style={{ textAlign: "right"}}>{((parseFloat(fakturaDataObject.arbeidSum) + parseFloat(fakturaDataObject.materiellSum) + parseFloat(fakturaDataObject.fremmoteSum)) * 0.25).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Totalt:</td><td style={{ textAlign: "right"}}>{((parseFloat(fakturaDataObject.arbeidSum) + parseFloat(fakturaDataObject.materiellSum) + parseFloat(fakturaDataObject.fremmoteSum)) * 1.25).toFixed(2)}</td>
                                                </tr>
                                              
                                            </tbody>
                                        </Table>

                                        <br></br><br></br>

                                        <div dangerouslySetInnerHTML={{ __html: fakturaDataObject.clientText  }} />
                    
                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={fakturaDataObject.Date} Montor={fakturaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />


                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default Fakturagrunnlag;