import React from "react";
import { Redirect } from "react-router-dom";
import Loader from "components/Loader";
import NotificationAlert from "react-notification-alert";
import { Base64 } from 'js-base64';
import config from "../../config.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";


class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "32704",
      userPwd: "U*LfR_@D",
      error: null,
      loading: false,
      loginOK: false,
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  notify = text => {
    var type = "danger";
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>OPS!</b> <br />
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  async handleClick() {

    if (!(this.state.userName && this.state.userPwd)) {
      return;
    }

    this.setState({ loading: true });

    try {
        var response = await fetch(config.server.connection.url + "/api/BoligrorleggerenCustomerController/Login", {
        method: "POST",
        headers: {
          'mode': 'no-cors',
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',  //no need for this???
          'Authorization': 'Basic ' + Base64.encode(this.state.userName + ":" + this.state.userPwd).replace(/['"]+/g, ''),
        },
      })
    } catch (ex) {
      console.log(ex);
      return;
    }

    if (!response.ok) {
      this.setState({
        loading: false,
        loginOK: false
      });
      this.notify("Kombinasjonen brukernavn og passord er feil")
      return;
    }

    this.setState({
      loading: false,
      loginOK: true,
    });
    return;
  }

  handleChange({ target }) {
    this.setState({
      [target.name]: target.value
    });
  }

  keyPressed(event) {
    if (event.key === "Enter") {
      document.getElementById('submitButton').click();
    }
  }


  componentDidMount(props) {
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  render() {
    const { error, loading } = this.state;

    //login ok
    if (this.state.loginOK) {
      var date = new Date();
      sessionStorage.setItem("id", this.state.userName);
      sessionStorage.setItem("value", this.state.userPwd);
      sessionStorage.setItem("time", date.getTime());
      return <Redirect to="/admin/elektriker" />
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (loading) {
      return (
        <div className="content">
          <Loader />
        </div>
      )

    } else {

      return (

        <div className="login-page" style={{paddingTop: 50 +"px"}}>

          <NotificationAlert ref="notificationAlert" />

          <Container>
            <Row style={{paddingBottom: 20 +"px"}}>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <center>
                  <img style={{width: 200 +"px"}} src="https://kunde.boligrorleggeren.no/boligrorleggeren_logo.png"></img>  
                </center>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form action="" className="form" method="">
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">logg inn</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Brukernavn"
                          type="text"
                          name="userName"
                          //value={this.state.userName}
                          onChange={this.handleChange} />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Passord"
                          name="userPwd"
                          //value={this.state.userPwd}
                          onChange={this.handleChange}
                          onKeyPress={this.keyPressed}
                          type="password"
                          autoComplete="off"
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        id='submitButton'
                        //color="warning"
                        href="#"
                        onClick={this.handleClick}
                      >
                        {this.state.isToggleOn ? 'Get Started' : 'Logg på'}
                        {/* {this.state.isToggleOn ? 'Get Started' : 'Log off'} opprinnelig stod den slik */}
                      </Button>

                    </CardFooter>
                    <br />
                    <a href="/auth/resetpwd"><p className="header text-center" style={{fontSize: 1.1 +"em", color: "#30BBDB"}}>glemt passord?</p></a>
                    <a href={sessionStorage.getItem("companyUrl")}>
                      <p className="header text-center" style={{fontSize: 1.1 +"em", color: "#30BBDB"}}>
                        {sessionStorage.getItem("companyUrl").substring(8, sessionStorage.getItem("companyUrl").length )}
                      </p>
                    </a>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>

          <div
            className="full-page-background"
            style={{
              backgroundImage: `url(${sessionStorage.getItem("companyBackgroundImage")})`
            }}
          />
        </div>
      );
    }
  }
}

export default Login;
