import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
// import AdminLayout from "layouts/Admin.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/loader.css";

import { AuthRequired } from "helpers/AuthRequired";
//import Login from "./views/pages/Login";


const hist = createBrowserHistory();


class App extends React.Component {

  constructor(props) {

    let url = window.location.href;

    if (url.includes("fixel")) {
      sessionStorage.setItem("company", "Boligelektrikeren");
      sessionStorage.setItem("companyID", "2");
      sessionStorage.setItem("companyIDAzureDB", "3");
      sessionStorage.setItem("companyUrl", "https://www.fixel.no");
      sessionStorage.setItem("companyInfo", "Gjerdrums vei 8, 0484 Oslo - Tlf: 815 00 005 - Orgnr: 994 519 611 - Kontonummer: 6042 06 38623 ");
      sessionStorage.setItem("companyLogoUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/fixel_full.png")
      sessionStorage.setItem("companyLogoSmallUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/fixel_small.png")
      sessionStorage.setItem("companyBackgroundImage", "https://www.fixel.no/wp-content/uploads/Kundeportal.jpg")
    }
    if (url.includes("elektris")) {
      sessionStorage.setItem("company", "Boligelektrikeren");
      sessionStorage.setItem("companyID", "4");
      sessionStorage.setItem("companyIDAzureDB", "2");
      sessionStorage.setItem("companyUrl", "https://www.elektris.no");
      sessionStorage.setItem("companyInfo", "Gjerdrums vei 8, 0484 Oslo - Tlf: 41 85 90 90 - Orgnr: 996 446 972 - Kontonummer: 6042 06 62249");
      sessionStorage.setItem("companyLogoUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/elektris_full.png")
      sessionStorage.setItem("companyLogoSmallUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/elektris_small.png")
      sessionStorage.setItem("companyBackgroundImage", "https://www.elektris.no/wp-content/uploads/2021/10/Kundeportal.jpg")
    }
    if (url.includes("elmesteren")) {
      sessionStorage.setItem("company", "Boligelektrikeren");
      sessionStorage.setItem("companyID", "3");
      sessionStorage.setItem("companyIDAzureDB", "4");
      sessionStorage.setItem("companyUrl", "https://www.elmesteren.no");
      sessionStorage.setItem("companyInfo", "Gjerdrums vei 8, 0484 Oslo - Tlf: 90 94 22 22 - Orgnr: 997 924 460 - Kontonummer: 6042 06 68182");
      sessionStorage.setItem("companyLogoUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/elmesteren_full.png")
      sessionStorage.setItem("companyLogoSmallUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/fixel_small.png")
      sessionStorage.setItem("companyBackgroundImage", "https://www.elmesteren.no/wp-content/uploads/2021/10/Kundeportal.jpg")
    }
    if (url.includes("elfiksern")) {
      sessionStorage.setItem("company", "Boligelektrikeren");
      sessionStorage.setItem("companyID", "6");
      sessionStorage.setItem("companyIDAzureDB", "5");
      sessionStorage.setItem("companyUrl", "https://www.elfikseren.no");
      sessionStorage.setItem("companyInfo", "Gjerdrums vei 8, 0484 Oslo - Tlf: 41 45 55 44 - Orgnr: 925 353 329 - Kontonummer: 6206 15 4424");
      sessionStorage.setItem("companyLogoUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/elfikseren_full.png")
      sessionStorage.setItem("companyLogoSmallUrl", "https://rokeagruppenkundeportal.azurewebsites.net/static/media/skiing.png")
      sessionStorage.setItem("companyBackgroundImage", "https://www.kunde.fixel.no/static/media/skiing.png")
    }
    if (url.includes("boligrorleggeren")) {
      sessionStorage.setItem("company", "Boligrørleggeren");
      sessionStorage.setItem("companyID", "4");
      sessionStorage.setItem("companyIDAzureDB", "2");
      sessionStorage.setItem("companyUrl", "https://www.boligrorleggeren.no");
      sessionStorage.setItem("companyInfo", "Gjerdrums vei 8, 0484 Oslo - Tlf: 41 85 90 90 - Orgnr: 996 446 972 - Kontonummer: 6042 06 62249");
      sessionStorage.setItem("companyLogoUrl", "https://kunde.boligrorleggeren.no/boligrorleggeren_logo.png")
      sessionStorage.setItem("companyLogoSmallUrl", "https://kunde.boligrorleggeren.no/boligrorleggeren_logo.png")
      sessionStorage.setItem("companyBackgroundImage", "https://boligrorleggeren.no/wp-content/uploads/2024/05/Boligrorleggeren_Forside%E2%80%93Hero_221021-2.jpg")
    }
    if (url.includes("localhost")) {
      sessionStorage.setItem("company", "Boligrørleggeren");
      sessionStorage.setItem("companyID", "4");
      sessionStorage.setItem("companyIDAzureDB", "2");
      sessionStorage.setItem("companyUrl", "https://www.boligrorleggeren.no");
      sessionStorage.setItem("companyInfo", "Gjerdrums vei 8, 0484 Oslo - Tlf: 41 85 90 90 - Orgnr: 996 446 972 - Kontonummer: 6042 06 62249");
      sessionStorage.setItem("companyLogoUrl", "https://kunde.boligrorleggeren.no/boligrorleggeren_logo.png")
      sessionStorage.setItem("companyLogoSmallUrl", "https://kunde.boligrorleggeren.no/boligrorleggeren_logo.png")
      sessionStorage.setItem("companyBackgroundImage", "https://boligrorleggeren.no/wp-content/uploads/2024/05/Boligrorleggeren_Forside%E2%80%93Hero_221021-2.jpg")
    }

    super(props);
    this.state = {
      redirectToReferrer: false
    };
  }

  render() {

    return (
      <Router history={hist}>
        <Switch>
          <Route
            path="/admin"
            render={() => (
              <AuthRequired
              //redirectTo='/auth/login' 
              //orRender={props => <AdminLayout {...props} />}
              />)}
          />
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          {/* <Route path="/admin" render={props => <AdminLayout {...props} />} /> */}
          <Redirect
            to={{
              pathname: "/auth/login",
            }}
          />

        </Switch>
      </Router>
    )
  }
}

export default App;