import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// import avatar from "assets/img/mattis.jpg";
import { Base64 } from 'js-base64';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes)
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
          customerProfile: null,
          isLoaded: false,
          isAdmin: this.props.isAdmin,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.invisible) return null;
      if (this.state.isAdmin) {
        if (!prop.adminView) {
          return null;
        }
      } else {
        if (!prop.employeeView) {
          return null;
        }
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="##"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  initPerfectScrollbar() {
    // // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentDidMount() {

    fetch("https://rokeagruppenapi.azurewebsites.net/api/BoligrorleggerenCustomerController/CustomerProfile", {
      //fetch("https://localhost:44347/api/Customer/CustomerProfile", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
        'CompanyID': sessionStorage.getItem("companyID"),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            customerProfile: result,
            isLoaded: true
          });

          this.initPerfectScrollbar()
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //handle error
        }
      )
      .then(
        () => {
          try {
            let cn = this.state.customerProfile.companyName;
            cn = cn.replace('-', '')
            this.setState({
              customerName: cn.substring(0, cn.search(/\d/)),
              customerNumber: cn.substring(cn.search(/\d/), cn.length),
            });
          }
          catch{
            this.setState({
              customerName: this.state.customerProfile.companyName,
              customerNumber: "",
            });
          }

        }
      )
  }

  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  logOut() {
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("value");
  }

  render() {

    const { isLoaded, customerProfile, customerName, customerNumber } = this.state;

    if (!isLoaded) {
      return (
        <></>
      )
    }

    let hrefLink = '#';

    return (

      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo" style={{paddingRight: 40 +"px"}}>
          <a
            href={sessionStorage.getItem("companyUrl")}
            className="simple-text logo-mini"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <div className="logo-img">
              <img src={sessionStorage.getItem("companyLogoSmallUrl")} alt="react-logo" />
            </div> */}
          </a>
          <a
            href={sessionStorage.getItem("companyUrl")}
            className="simple-text logo-normal"
          >
            <img alt="logo" src={sessionStorage.getItem("companyLogoUrl")}></img>
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            {/* <div className="photo">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSq6Gg8Tc_dV9rTibw6LCHY_xTWchudkWPe-g&usqp=CAU" alt="Avatar" />
            </div> */}
            <div className="info">
              <a
                href={hrefLink}
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span style={{ paddingBottom: 10 + "px" }}>
                  {customerName}
                </span>
                <span>
                  Kundenr.: {customerNumber}
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>

                <ul className="nav">
                  <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">MI</span>
                      <span className="sidebar-normal">Kundeinformasjon</span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/admin/changepwd" activeClassName="">
                      <span className="sidebar-mini-icon">EP</span>
                      <span className="sidebar-normal">Endre passord</span>
                    </NavLink>
                  </li> */}

                  <li>
                    <NavLink to='/' onClick={this.logOut} activeClassName="">
                      <span className="sidebar-mini-icon">LU</span>
                      <span className="sidebar-normal">Logg ut</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>

          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
