import React from "react";
import ReactTable from "react-table";
import Loader from "components/Loader";
import { Slider, Radio, FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
import ReactBSAlert from "react-bootstrap-sweetalert";
import PriceSubReasons from '../forms/PriceSubReasons'
import SourceCustomer from '../forms/SourceCustomer'
import { Base64 } from 'js-base64';
import config from "../../config.js";
import { Collapse} from 'reactstrap';




// reactstrap components
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Modal,
} from "reactstrap";

// schemas
import Reklamasjon from "../../Schemas/reklamasjon";
import Befaring from "../../Schemas/befaring";
import Velkomstbrev from "../../Schemas/velkomstbrev";
import Fakturagrunnlag from "../../Schemas/fakturagrunnlag";
import FDVDocumentation from "../../Schemas/fdvDocumentation";



const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

class ServiceJobs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      jobsLoading: false,
      error: false,
      customerJobSchemasArray: [],
      customerJobArray: [],
      selectedRow: null,
      rowexpanded: null,
      expanded: {},
      modalSurvey: false,
      showSamsvarSchema: false,
      showRisikovurderingSchema: false,
      jobId: "",
      invoiceID: "",
      employeeId: "",
      schemaId: null,
      txtOtherReasonDisabled: true,
      showPriceSubReasons: false,
      showSources: false,
      radioSourceSelected: "",
      radioSelected: "",
      prisScore: null,
      kvalitetScore: null,
      effektivitetScore: null,
      palitelighetScore: null,
      anbefaleScore: 0,
      gjenbrukScore: 0,
      totalprisScore: 0,
      newCustomerRadio: 0,
      buttonText: 'Neste',

      DIV_tilfredshet_Visibility: 'block',
      DIV_kampanjeOnsker_Visibility: 'none',
      DIV_abonnement_Visibility: 'none',
      steg: 'STEG 1 AV 3',
      introText: 'Vi ønsker alltid og forbedre oss og ber pent om at du svarer på noen spørsmål. Undersøkelsen tar kun ett til to minutter å gjennomføre.',
      campaignRadio: 0,
      abonnementRadio: 0,
      campaignAnnetText: '',
      campaignAnnetTextDisabled: true,
      campaignCommentText: '',
      campaignCommentTextDisabled: true,

      DIV_CampaignExecution_Visibility: 'none',
      campaignExecutionOnDateTextDisabled: true,
      campaignExecuteOnSpecificDateText: '',
      campaignContactPreference: '',

      DIV_AbonnementExecution_Visibility: 'none',
      abonnementExecutionOnDateTextDisabled: true,
      abonnementExecuteOnSpecificDateText: '',
      abonnementContactPreference: '',
      
      panel1: false,
      panel2: false,
    };

    this.modalSurvey = this.modalSurvey.bind(this);
    this.submitSurveyStepOne = this.submitSurveyStepOne.bind(this);
    this.submitSurveyStepTwo = this.submitSurveyStepTwo.bind(this);
    this.submitSurveyStepThree = this.submitSurveyStepThree.bind(this);
    this.validateSurveySteps = this.validateSurveySteps.bind(this);
    this.setAnswer = this.setAnswer.bind(this);
    this.setPrisSubReason = this.setPrisSubReason.bind(this);
    this.setSourceCustomer = this.setSourceCustomer.bind(this);
    this.checkIfChecked = this.checkIfChecked.bind(this);
    this.getSchemas = this.getSchemas.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.checkIfSourceChecked = this.checkIfSourceChecked.bind(this);
    this.handleSourceRadioChecked = this.handleSourceRadioChecked.bind(this);
    this.handleCampaignRadioChange = this.handleCampaignRadioChange.bind(this);
    this.handleAbonnementRadioChange = this.handleAbonnementRadioChange.bind(this);
    this.handleCampaignExecutionRadioChange = this.handleCampaignExecutionRadioChange.bind(this)
    this.handleTxtCampaignExecuteOnSpecificDate = this.handleTxtCampaignExecuteOnSpecificDate.bind(this)
    
    this.handleAbonnementExecutionRadioChange = this.handleAbonnementExecutionRadioChange.bind(this)
    this.handleTxtAbonnementExecuteOnSpecificDate = this.handleTxtAbonnementExecuteOnSpecificDate.bind(this)
    this.handleCampaignContactPreference = this.handleCampaignContactPreference.bind(this)
    this.handleAbonnementContactPreference = this.handleAbonnementContactPreference.bind(this)

    this.handlePanelChange = this.handlePanelChange.bind(this)
  }


  handlePanelChange = (event) =>{
     this.setState({
      [event.target.name] : !this.state[event.target.name]
    });
  };

  handleTxtAbonnementExecuteOnSpecificDate(value){
    this.setState({
      abonnementExecuteOnSpecificDateText: value,
    });
  }

  modalSurvey(jobId, employeeId) {
    this.setState({
      DIV_tilfredshet_Visibility: 'block',
      //modalSurvey: !this.state.modalSurvey,
      jobId: jobId,
      employeeId: employeeId,
    });
  };

  componentDidMount() {

    this.setState({
      jobsLoading: true,
    });

    //Load customer jobs
    fetch(config.server.connection.url + "/api/BoligrorleggerenCustomerController/GetJobs", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
        'CompanyID': sessionStorage.getItem("companyID"),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            customerJobArray: result.map((prop, key) => {
              return {
                id: key,
                jobId: prop['JobId'],
                employeeId: prop['EmployeeId'],
                date: prop['Date'],
                invoice: prop['InvoiceId'],
                surveyCompleted: (prop['SurveyCompleted']) ? 'true' : 'false'
              }
            }),
            jobsLoading: false
          });
        },
        (error) => {
          this.setState({
            jobsLoading: false,
            error: true
          });
        }
      )
  }

  
  
  getSchemas(jobId, invoiceId) {
    this.setState({
      invoiceID: invoiceId,
    });
    fetch(config.server.connection.url + "/api/BoligrorleggerenCustomerController/GetSchemas?jobId=" + jobId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
        'CompanyID': sessionStorage.getItem("companyID"),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            customerJobSchemasArray: result.map((prop, key) => {
              return {
                id: key,
                col1: jobId,
                col2: ['Fakturagrunnlag'][key], // Assigning values from the fixed array
                actions: (
                  // custom button actions
                  <div className="actions-right">
                    {/* button to open schema */}
                    <Button
                      onClick={() => {
                        let obj = this.state.customerJobSchemasArray.find(o => o.id === key);
                        console.log(obj)
                        this.setState({
                          jobId: obj.col1,
                          schemaId: obj.col2,
                        });
                      }}
                      color="success"
                      size="md"
                      className="btn-icon btn-link edit"
                    >
                      <i className="fa fa-folder-open" />
                    </Button>{" "}
                  </div>
                )
              }
            })
          })
        })
  }

  errorAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="OPS!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Takk for hjelpen!"
          onConfirm={() => this.success()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
        </ReactBSAlert>
      )
    });
  };

  success = () => {
    this.hideAlert();
    window.location.reload(false)
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  setAnswer(id, val) {
    if (id === "pris" & val < 5) {
      this.setState({
        showPriceSubReasons: true,
        prisScore: val,
      });
      return;
    }

    if (id === "pris" & val > 4) {
      this.setState({
        showPriceSubReasons: false,
        prisScore: val,
      });
      return;
    }

    if (id === "kvalitet") {
      this.setState({
        kvalitetScore: val,
      });
      return;
    }

    if (id === "effektivitet") {
      this.setState({
        effektivitetScore: val,
      });
      return;
    }

    if (id === "pålitelighet") {
      this.setState({
        palitelighetScore: val,
      });
      return;
    }

    if (id === "gjenbruk") {
      this.setState({
        gjenbrukScore: val,
      });
      return;
    }

    if (id === "anbefale") {
      this.setState({
        anbefaleScore: val,
      });
      return;
    }
    if (id === "totalpris") {
      this.setState({
        totalprisScore: val,
      });
      return;
    }
  }

  setPrisSubReason(val) {

    if (val !== "annet") {
      this.setState({
        radioSelected: val,
        txtOtherReasonDisabled: true,
      });
      return;
    } else {
      this.setState({
        radioSelected: val,
        txtOtherReasonDisabled: false,
      });
      return;
    }
  }

  setSourceCustomer(val) {
    this.setState({
      radioSourceSelected: val,
    });
    return;
  }

  checkIfChecked(val) {
    console.log('CHECK ->>')
    // console.log(val)
    if (val === this.state.radioSelected) {
      return true;
    }
    return false;
  }

  handleChange(name, event) {
    this.annetText = event.target.value;
  }

  handleTxtCampaignAnnetText(name, event) {
    this.setState({
      campaignAnnetText: name,
    });
  }

  handleTxtCampaignCommentText(name, event) {
    this.setState({
      campaignAnnetText: name,
    });
  }

  
  getFooterData = () => {
    this.hideAlert();
    window.location.reload(false)
  }

  async validateSurveySteps(event) {
    console.log("VALIDATING SURVEY STEP")
    if (this.state.DIV_tilfredshet_Visibility === 'block') //TILFREDSHET
    {
      console.log("validation tilfredshet")
      //validate all input values for STEP 1 are registered
      if (this.state.newCustomerRadio === 0) {
        this.errorAlert("Vennligst oppgi om du er ny eller eksisterende kunde")
        return;
      }
      if (this.state.prisScore === null || this.state.kvalitetScore === null || this.state.effektivitetScore === null || this.state.palitelighetScore === null || this.state.gjenbrukScore === 0 || this.state.anbefaleScore === 0 || this.state.totalprisScore === 0) {
        this.errorAlert("Vennligst gi en score for alle områder")
        return;

      }
      if (this.state.prisScore < 5) {
        if (this.state.radioSelected === "") {
          this.errorAlert("Vennligst spesifiser hvorfor du var misfornøyd med prisen")
          return;
        }
      }
      if (this.state.radioSelected !== 'annet') {
        this.annetText = this.state.radioSelected
      }

      this.submitSurveyStepOne()


      this.setState({
        DIV_tilfredshet_Visibility: 'none',
        DIV_kampanjeOnsker_Visibility: 'block',
        steg: 'STEG 2 AV 3',
        introText: ''
      });
    }

    if (this.state.DIV_kampanjeOnsker_Visibility === 'block') //KAMPANJER
    {
      console.log("VALIDATION CAMPAIGN")
      //validate all input values for STEP 2 are registered
      if (this.state.campaignRadio === 0) {
        this.errorAlert("Vennligst velg ønsket kampanje")
        return;
      }
      if (this.state.campaignAnnetTextDisabled !== true && this.state.campaignAnnetText === '') {
        this.errorAlert("Vennligst spesifiser hvilken annen kampanje som kunne være av interesse for deg.")
        return;
      }
      if (this.state.campaignExecuteOnSpecificDateText === '' && this.state.campaignRadio != 7) {
        this.errorAlert("Vennligst spesifiser når du ønsker at oppdraget skal utføres.")
        return;
      }
      if (this.state.campaignContactPreference === '' && this.state.campaignRadio != 7) {
        this.errorAlert("Vennligst spesifiser hvordan du ønsker å bli kontaktet.")
        return;
      }

      this.submitSurveyStepTwo()

      this.setState({
        DIV_kampanjeOnsker_Visibility: 'none',
        DIV_abonnement_Visibility: 'block',
        buttonText: 'Ferdig',
        steg: 'STEG 3 AV 3',
      });
    }

    if (this.state.DIV_abonnement_Visibility === 'block') //ABONNEMENT
    {
      console.log("VALiDATION ABONNEMENT")
      console.log(this.state.abonnementRadio)
      //validate all input values for STEP 3 are registered
      if (this.state.abonnementRadio === 0) {
        this.errorAlert("Vennligst velg et abonnement som kunne være av interesse for deg.")
        return;
      }
      if (this.state.abonnementExecutionOnDateTextDisabled !== true && this.state.abonnementExecuteOnSpecificDateText === '') {
        this.errorAlert("Vennligst spesifiser når du ønsker at oppdraget skal utføres.")
        return;
      }
      if (this.state.abonnementContactPreference === '' && this.state.abonnementRadio != 5) {
        this.errorAlert("Vennligst spesifiser hvordan du ønsker å bli kontaktet.")
        return;
      }

      this.submitSurveyStepThree()
    }

  }

  async submitSurveyStepOne() {
    console.log("IN SUBMIT SURVEY STEP ONE")

    try {
      console.log("Trying to fetch")
      var response = await fetch(global.config.server.connection.url + "/api/Customer/CustomerSurveyAnswersStepOne?customerId=" + sessionStorage.id + "&jobId=" + this.state.jobId + "&employeeId=" + this.state.employeeId + "&prisScore=" + this.state.prisScore + "&kvalitetScore=" + this.state.kvalitetScore + "&effektivitetScore=" + this.state.effektivitetScore + "&palitelighetScore=" + this.state.palitelighetScore + "&gjenbrukScore=" + this.state.gjenbrukScore + "&anbefaleScore=" + this.state.anbefaleScore + "&totalprisScore=" + this.state.totalprisScore + "&annetText=" + this.annetText + "&companyID=" + sessionStorage.getItem("companyIDAzureDB") + "&newCustomer=" + this.state.newCustomerRadio + "&source=" + this.state.radioSourceSelected, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
          'CompanyID': sessionStorage.getItem("companyID"),
        },
      })
    } catch (ex) {
      console.log(ex)
      return;
    }

    return
  };

  async submitSurveyStepTwo() {
    console.log("IN SUBMIT SURVEY STEP TWO")

    try {
      console.log("Trying to fetch")
      var response = await fetch(global.config.server.connection.url + "/api/Customer/CustomerSurveyAnswersStepTwo?customerId=" + sessionStorage.id + "&jobId=" + this.state.jobId + "&employeeId=" + this.state.employeeId + "&companyID=" + sessionStorage.getItem("companyIDAzureDB") + "&newCustomer=" + this.state.newCustomerRadio + "&selectedCampaign=" + this.state.campaignRadio + "&campaignAnnetText=" + this.state.campaignAnnetText + "&selectedCampaignDate=" + this.state.campaignExecuteOnSpecificDateText + "&campaignContactPreference=" + this.state.campaignContactPreference, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
          'CompanyID': sessionStorage.getItem("companyID"),
        },
      })
    } catch (ex) {
      console.log(ex)
      return;
    }

    return
  };

  async submitSurveyStepThree() {
    this.setState({
      modalSurvey: !this.state.modalSurvey,
    });
    try {
      var response = await fetch(global.config.server.connection.url + "/api/Customer/CustomerSurveyAnswersStepThree?customerId=" + 
      sessionStorage.id + "&jobId=" + this.state.jobId + 
      "&employeeId=" + this.state.employeeId + 
      "&companyID=" + sessionStorage.getItem("companyIDAzureDB") + 
      "&newCustomer=" + this.state.newCustomerRadio +
      "&selectedCampaign=" + this.state.campaignRadio + 
      "&selectedAbonnement=" + this.state.abonnementRadio + 
      "&campaignAnnetText=" + this.state.campaignAnnetText + 
      "&selectedCampaignDate=" + this.state.campaignExecuteOnSpecificDateText + 
      "&selectedAbonnementDate=" + this.state.abonnementExecuteOnSpecificDateText + 
      "&abonnementContactPreference=" + this.state.abonnementContactPreference + 
      "&campaignContactPreference=" + this.state.campaignContactPreference, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
          'CompanyID': sessionStorage.getItem("companyID"),
        },
      })
    } catch (ex) {
      console.log(ex)
      return;
    }

    if (response.ok) {
      this.successAlert()
      return;
    }

    if (!response.ok) {
      console.log(response)
      console.log("RESPONSE NOT OK")
      return;
    }
  };

  getData = (rowData) => {
    // This is the row data from ChildComponent

    this.annetText = rowData;
  }


  handleRadioChange(name, event) {
    console.log('NAME: ', name, ' EVENT:', event)
    if (event === 'nei') {
      this.setState({
        showSources: true,
      });
    } else {
      this.setState({
        showSources: false,
      });
    }
    this.setState({
      newCustomerRadio: event,
    });
  }

  handleCampaignRadioChange(name, event) {
    
    if (event === '7') {
      this.setState({
        DIV_CampaignExecution_Visibility: 'none'
      });
    }else {
      this.setState({
        DIV_CampaignExecution_Visibility: 'block'
      });
    }

    if (event === '6') {
      this.setState({
        campaignAnnetTextDisabled: false,
        campaignCommentTextDisabled: true,
      });
    } else {
      this.setState({
        campaignAnnetTextDisabled: true,
        campaignCommentTextDisabled: false,

      });
    }

    this.setState({
      campaignRadio: event,
    });
  }

  handleCampaignExecutionRadioChange(name, event) {
    if (event === '5') {
      this.setState({
        campaignExecutionOnDateTextDisabled: false,
        campaignExecuteOnSpecificDateText: '',
      });
    } else {
      this.setState({
        campaignExecuteOnSpecificDateText: event,
        campaignExecutionOnDateTextDisabled: true,

      });
    }
  }

  handleTxtCampaignExecuteOnSpecificDate(value){
    this.setState({
      campaignExecuteOnSpecificDateText: value,
    });
  }

  handleCampaignContactPreference(name, event) {
    this.setState({
      campaignContactPreference: event,
    });
  }

  handleAbonnementRadioChange(name, event) {
    
    if (event === '5') {
      this.setState({
        DIV_AbonnementExecution_Visibility: 'none'
      });
    }else {
      this.setState({
        DIV_AbonnementExecution_Visibility: 'block'
      });
    }

    this.setState({
      abonnementRadio: event,
    });
  }

  handleAbonnementExecutionRadioChange(name, event) {
    console.log(event)
    if (event === '5') {
      this.setState({
        abonnementExecutionOnDateTextDisabled: false,
        abonnementExecuteOnSpecificDateText: '',
      });
    } else {
      this.setState({
        abonnementExecuteOnSpecificDateText: event,
        abonnementExecutionOnDateTextDisabled: true,

      });
    }
  }

  handleTxtAbonnementExecuteOnSpecificDate(value){
    this.setState({
      abonnementExecuteOnSpecificDateText: value,
    });
  }

  handleAbonnementContactPreference(name, event) {
    this.setState({
      abonnementContactPreference: event,
    });
  }

  checkIfSourceChecked(val) {
    if (val === this.state.radioSourceSelected) {
      return true;
    }
    return false;
  }

  handleSourceRadioChecked(name, event) {

    this.setState({
      radioSourceSelected: name,
    });
  }

  render() {

    const { jobsLoading, customerJobSchemasArray, schemaId, jobId, invoiceID } = this.state;

    if (jobsLoading) {
      return <Loader />
    }


    console.log(schemaId)

    switch (schemaId) {
      case "Befaring": 
        return <Befaring jobId={jobId} footerText={sessionStorage.getItem("company") + " - " + sessionStorage.getItem("companyInfo")} companyUrl={sessionStorage.getItem("companyUrl")} />
      case "Fakturagrunnlag": 
        return <Fakturagrunnlag jobId={jobId} invoiceId={invoiceID} footerText={sessionStorage.getItem("company") + " - " + sessionStorage.getItem("companyInfo")} companyUrl={sessionStorage.getItem("companyUrl")} />
      case "Reklamasjon": 
        return <Reklamasjon jobId={jobId} footerText={sessionStorage.getItem("company") + " - " + sessionStorage.getItem("companyInfo")} companyUrl={sessionStorage.getItem("companyUrl")} />
      case "FDV Dokumentasjon": 
        return <FDVDocumentation jobId={jobId} footerText={sessionStorage.getItem("company") + " - " + sessionStorage.getItem("companyInfo")} companyUrl={sessionStorage.getItem("companyUrl")} />
      default:
    }

    return (
      <>
        {this.state.alert}

        <div className="content">

          <Modal
            style={{ maxWidth: '900px' }}
            isOpen={this.state.modalSurvey}
            toggle={this.modalSurvey}
          >
            <div className="modal-header justify-content-center">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.modalSurvey}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h4 className="title title-up">Spørreundersøkelse etter utført arbeid</h4>
              <h5>{this.state.introText}</h5>
              <div>
                <b><span style={{ color: '#6bd098' }}>{this.state.steg}</span></b>
              </div>
            </div>



            <div id="tilfredshetDIV" className="modal-body" style={{ display: this.state.DIV_tilfredshet_Visibility }}>
              <div className="modal-body">
                <center>
                  <b style={{ size: 14 + 'px', color: '#ED9D7A' }}><span style={{ color: '#6bd098' }}>Elektris, Elfiksern, Elmesteren og Fixel</span> slår seg sammen og blir til Boligelektrikeren!
                    <br></br>Dette for kunne tilby alle våre gamle og nye kunder den beste elektrikertjenesten.. </b> <br></br>
                  <br></br><br></br>
                </center>
                <b style={{ color: '#000' }}>VI TRENGER DIN HJELP FOR Å DANNE ET BEST MULIG BILDE AV DIN OPPLEVELSE SOM KUNDE HOS OSS. </b> <br></br>
              </div>
              <p style={{ marginLeft: 50 + 'px' }}>------------------------------------</p>
              <div className="modal-body">
                <b>Har du tidligere vært kunde av noen av selskapene under?</b><br></br><br></br>
                <ul>
                  <li style={{ marginBottom: 7 + 'px' }}>Elektris</li>
                  <li style={{ marginBottom: 7 + 'px' }}>Elfiksern</li>
                  <li style={{ marginBottom: 7 + 'px' }}>Elmesteren</li>
                  <li>Fixel</li>
                </ul>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="tidligereKunde-radio-buttons-group"
                    onChange={this.handleRadioChange}
                  >
                    <FormControlLabel value="ja" control={<Radio />} label="Ja" />
                    <FormControlLabel value="nei" control={<Radio />} label="Nei" />
                  </RadioGroup>
                </FormControl>

                {this.state.showSources ?
                  <SourceCustomer handleTxtType={this.getData} handleSourceRadioChecked={this.handleSourceRadioChecked}
                    checkIfSourceChecked={this.checkIfSourceChecked} /> : null}

                <br></br><br></br>
                <p>Under vil du få syv spørsmål hvor du skal svare med en verdi fra <b>1 – 10</b>.<br></br>
                  Hvor <b>1</b> er langt <b>dårligere</b> enn forventet og <b>10</b> er langt <b>bedre</b> enn forventet.</p>
              </div>

              <div className="modal-body">
                <Col md="12">
                  <b>Pris</b>
                </Col>
                <Col md="6">
                  <Slider
                    id="slider"
                    onChange={(e, val) => this.val = val}
                    onChangeCommitted={(e) => this.setAnswer("pris", this.val)}
                    defaultValue={0}
                    aria-labelledby="discrete-slider-small-steps"
                    step={null}
                    marks={marks}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                </Col>

                {this.state.showPriceSubReasons ?
                  <PriceSubReasons annetEnabled={this.state.txtOtherReasonDisabled} handleTxtType={this.getData} handleRadioChecked={this.setPrisSubReason}
                    checkIfChecked={this.checkIfChecked} /> : null}


                <br></br>

                <Col md="12">
                  <b>Kvalitet</b>
                </Col>
                <Col md="6">
                  <Slider
                    id="slider"
                    val="22"
                    onChange={(e, val) => this.val = val}
                    onChangeCommitted={(e) => this.setAnswer("kvalitet", this.val)}
                    defaultValue={0}
                    aria-labelledby="discrete-slider-small-steps"
                    step={null}
                    marks={marks}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                </Col>

                <br></br>

                <Col md="12">
                  <b>Effektivitet</b>
                </Col>
                <Col md="6">
                  <Slider
                    id="slider"
                    val="22"
                    onChange={(e, val) => this.val = val}
                    onChangeCommitted={(e) => this.setAnswer("effektivitet", this.val)}
                    defaultValue={0}
                    aria-labelledby="discrete-slider-small-steps"
                    step={null}
                    marks={marks}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                </Col>

                <br></br>

                <Col md="12">
                  <b>Pålitelighet</b>
                </Col>
                <Col md="6">
                  <Slider
                    id="slider"
                    val="22"
                    onChange={(e, val) => this.val = val}
                    onChangeCommitted={(e) => this.setAnswer("pålitelighet", this.val)}
                    defaultValue={0}
                    aria-labelledby="discrete-slider-small-steps"
                    step={null}
                    marks={marks}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                </Col>

                <Col md="12">
                  <b>Hvor sannsynlig er det at du vil benytte {sessionStorage.getItem('company')} igjen, neste gang du har behov for elektrikertjenester?</b>
                </Col>
                <Col md="6">
                  <Slider
                    id="slider"
                    val="22"
                    onChange={(e, val) => this.val = val}
                    onChangeCommitted={(e) => this.setAnswer("gjenbruk", this.val)}
                    defaultValue={0}
                    aria-labelledby="discrete-slider-small-steps"
                    step={null}
                    marks={marks}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                </Col>

                <Col md="12">
                  <b>Hvor sannsynlig er det at du vil anbefale {sessionStorage.getItem('company')} til ditt nettverk, om de har behov for elektrikertjenester?</b>
                </Col>
                <Col md="6">
                  <Slider
                    id="slider"
                    val="22"
                    onChange={(e, val) => this.val = val}
                    onChangeCommitted={(e) => this.setAnswer("anbefale", this.val)}
                    defaultValue={0}
                    aria-labelledby="discrete-slider-small-steps"
                    step={null}
                    marks={marks}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                </Col>


                <Col md="12">
                  <b> I forhold til den etterspurte tjenesten og det som ble levert, hva synes du om totalprisen?</b>
                </Col>
                <Col md="6">
                  <Slider
                    id="slider"
                    val="22"
                    onChange={(e, val) => this.val = val}
                    onChangeCommitted={(e) => this.setAnswer("totalpris", this.val)}
                    defaultValue={0}
                    aria-labelledby="discrete-slider-small-steps"
                    step={null}
                    marks={marks}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                  />
                </Col>

              </div>
            </div>

            <div id="kampanjeOnskerDIV" className="modal-body" style={{ display: this.state.DIV_kampanjeOnsker_Visibility }}>
              <div className="modal-body">
                <b style={{ color: '#000' }}>VELG DIN NESTE KAMPANJE </b> <br></br>
                <br></br>
                <b>Vår neste kampanje er kanskje interessant for deg? Hvis du kunne velge - hva ville vært din foretrukne kampanje?</b>
                <br></br><br></br>
                <b style={{ size: 14 + 'px', color: '#ED9D7A' }}><i>NB: Dette er ikke et tilbud, men kan benyttes til å skreddersy din neste kampanje</i></b>
              </div>
              <div className="modal-body">

                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={this.handleCampaignRadioChange}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="20% på lys" />
                    <FormControlLabel value="2" control={<Radio />} label="20% på nytt strømpunkt" />
                    <FormControlLabel value="3" control={<Radio />} label="50% på elsjekk" />
                    <FormControlLabel value="4" control={<Radio />} label="Kampanje på bytte av sikringsskap" />
                    <FormControlLabel value="5" control={<Radio />} label="Kampanje på oppussing" />
                    <FormControlLabel value="6" control={<Radio />} label="Annet" />
                    <Input type="text"
                      value={this.campaignAnnetText}
                      disabled={this.state.campaignAnnetTextDisabled}
                      onChange={(event) => this.handleTxtCampaignAnnetText(event.target.value)}
                      placeholder="Skriv ditt neste kampanjeønske her"
                    />
                    <FormControlLabel value="7" control={<Radio />} label="Ikke interessert" />
                  </RadioGroup>
                  <br></br>

                  <div id="execution"  style={{ display: this.state.DIV_CampaignExecution_Visibility }}>
                    <b> Når ønsker du at oppdraget skal utføres?</b>

                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={this.handleCampaignExecutionRadioChange}
                    >
                      <FormControlLabel value="Innen 24 timer" control={<Radio />} label="Innen 24 timer" />
                      <FormControlLabel value="Innen 1 uke" control={<Radio />} label="Innen 1 uke" />
                      <FormControlLabel value="Innen 1-2 uker" control={<Radio />} label="Innen 1-2 uker" />
                      <FormControlLabel value="Fleksibel" control={<Radio />} label="Fleksibel" />
                      <FormControlLabel value="5" control={<Radio />} label="Må skje på en bestemt dato" />
                      <Input type="text"
                        value={this.campaignExecuteOnSpecificDateText}
                        disabled={this.state.campaignExecutionOnDateTextDisabled}
                        onChange={(event) => this.handleTxtCampaignExecuteOnSpecificDate(event.target.value)}
                        placeholder="Oppgi den dato eller de datoene oppdraget må utføres"
                      />
                    </RadioGroup>
                    <br></br>
                    <b> Hvordan ønsker du å bli kontaktet?</b>

                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={this.handleCampaignContactPreference}
                    >
                      <FormControlLabel value="Telefon" control={<Radio />} label="Telefon" />
                      <FormControlLabel value="Epost" control={<Radio />} label="Epost" />
                      <FormControlLabel value="Tar kontakt selv" control={<Radio />} label="Du ønsker å komme tilbake til oss når det passer deg." />
                    </RadioGroup>

                  </div>

                  <hr></hr>
                  <p>Kommentar til Boligelektrikeren:</p>
                  <Input type="text" value={this.campaignAnnetText}
                    disabled={this.state.campaignCommentTextDisabled}
                    onChange={(event) => this.handleTxtCampaignCommentText(event.target.value)} />
                </FormControl>


              </div>
            </div>

            <div id="abonnementDIV" className="modal-body" style={{ display: this.state.DIV_abonnement_Visibility }}>
              <div className="modal-body">
                <b style={{ color: '#000' }}>VÅRE ABONNEMENT </b> <br></br><br></br>
                <b>Velg et abonnement som kunne være av interesse for deg.</b>
                <br></br><br></br>
                <b style={{ size: 14 + 'px', color: '#ED9D7A' }}><i>NB: Dette er kun en undersøkelse, og ingen abonnement vil aktiveres.</i></b>
              </div>
              <div className="modal-body">

                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={this.handleAbonnementRadioChange}
                  >
                    <Row>
                      <Col md="10">
                        <FormControlLabel value="6" control={<Radio />} label="Strømgaranti. (NOK 899 pr. mnd - to års bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel1" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel1}>
                        <Card>
                          <CardBody>
                            Ved strømbrudd på en kurs eller hele boligen, vil Boligelektrikeren være hos deg innen 1 time og gi deg strømmen tilbake.
                            Inkluderer også bytte av batterier i ditt alarmapparat ila. 24t fra du tar kontakt og bytte av lyspære 24t fra du tar kontakt
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 

                    <Row>
                      <Col md="10">
                      <FormControlLabel value="1" control={<Radio />} label="Bytting av lyspærer og batterialarmer. (NOK 399 pr. mnd - ingen bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel2" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel2}>
                        <Card>
                          <CardBody>
                          Om lyspærer går eller du skal bytte batterier i ditt alarmapparat - er det bare å ringe oss så kommer vi innen 24 timer fra du tar kontakt. 
                          Du kan ringe oss så mange ganger du ønsker.
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 

                    <Row>
                      <Col md="10">
                        <FormControlLabel value="2" control={<Radio />} label="Småfix - el og annet. (NOK 999 pr. mnd - ingen bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel3" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel3}>
                        <Card>
                          <CardBody>
                            Med en <i>småfix</i> avtale kan vi montere stort sett alt for deg så lenge vekten er ikke overstiger 20 kg, og at bredde og lengde ikke er lenger enn 1 m ( <i>merk: dettet inkluderer ikke el produkter men allerede feilmonterte produkter kan rettes</i>).
                            Med <i>småfix</i> får du også hjelp med montering av mindre hvite- og brunnvarer. Vi rykker ut innen 24 timer etter at du har ringt!
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 

                    <Row>
                      <Col md="10">
                      <FormControlLabel value="3" control={<Radio />} label="Boligalarm med videovervåking og bevegelsessensor. (NOK 950 pr. mnd - ett års bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel4" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel4}>
                        <Card>
                          <CardBody>
                          Du får levert og montert en boligalarmpakke. Denne kobles til din telefon slik at du kan følge med på hva som skjer i din bolig til enhver tid.
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 

                    <Row>
                      <Col md="10">
                      <FormControlLabel value="4" control={<Radio />} label="Smarthus pakken. (NOK 890 pr. mnd - ingen bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel5" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel5}>
                        <Card>
                          <CardBody>
                          Du tar kontakt når det passer deg for å få hjelp med smarthusløsningene dine
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 

                    <Row>
                      <Col md="10">
                      <FormControlLabel value="7" control={<Radio />} label="Bytt strømleverandør. (Prismatch - ingen bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel6" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel6}>
                        <Card>
                          <CardBody>
                          Her flyttes strøm abonnementet ditt til oss. Vi matcher prisen du får i dag. I tillegg får du strøm garanti.
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 

                    <Row>
                      <Col md="10">
                      <FormControlLabel value="8" control={<Radio />} label="Just Green. (Markedspris - ingen bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel7" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel7}>
                        <Card>
                          <CardBody>
                          Ved å velge dette strøm abonnementet garanterer vi at strømmen du bruker hos deg kommer fra fornybar kilder. Sammen med oss vil du bidra til bedre klima og miljø.
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 

                    
                    <Row>
                      <Col md="10">
                      <FormControlLabel value="9" control={<Radio />} label="Strøm og mobil pakken. (Prismatch - ingen bindingstid)" />
                      </Col>
                      <Col md="2">
                        <i className="fa fa-info" aria-hidden="true" onClick={this.handlePanelChange} style={{ fontSize: 1.2 + 'em', marginTop: '1rem', color: '#6bd098' }}>
                          <span style={{ display: 'inline' }}><a href="#" name="panel8" style={{ color: '#6bd098' }}> Les mer</a></span>
                        </i>
                      </Col>
                      <Collapse isOpen={this.state.panel8}>
                        <Card>
                          <CardBody>
                          Her flyttes strøm og mobil abonnementet ditt til oss. Vi matcher prisen du får i dag. I tillegg får du strøm garanti.
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Row> 
                    

                    <FormControlLabel value="5" control={<Radio />} label="Ikke interessert." />
                  </RadioGroup>

                  <div id="execution"  style={{ display: this.state.DIV_AbonnementExecution_Visibility }}>
                    <b> Når ønsker du å bli kontaktet?</b>

                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={this.handleAbonnementExecutionRadioChange}
                    >
                      <FormControlLabel value="Innen 24 timer" control={<Radio />} label="Innen 24 timer" />
                      <FormControlLabel value="Innen 1 uke" control={<Radio />} label="Innen 1 uke" />
                      <FormControlLabel value="Innen 1-2 uker" control={<Radio />} label="Innen 1-2 uker" />
                      <FormControlLabel value="Fleksibel" control={<Radio />} label="Fleksibel" />
                      <FormControlLabel value="5" control={<Radio />} label="Må skje på en bestemt dato" />
                      <Input type="text"
                        value={this.abonnementExecuteOnSpecificDateText}
                        disabled={this.state.abonnementExecutionOnDateTextDisabled}
                        onChange={(event) => this.handleTxtAbonnementExecuteOnSpecificDate(event.target.value)}
                        placeholder="Oppgi den dato eller de datoene oppdraget må utføres"
                      />
                    </RadioGroup>
                    <br></br>
                    <b> Hvordan ønsker du å bli kontaktet?</b>

                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={this.handleAbonnementContactPreference}
                    >
                      <FormControlLabel value="Telefon" control={<Radio />} label="Telefon" />
                      <FormControlLabel value="Epost" control={<Radio />} label="Epost" />
                      <FormControlLabel value="Tar kontakt selv" control={<Radio />} label="Du ønsker å komme tilbake til oss når det passer deg." />
                    </RadioGroup>
                  </div>


                </FormControl>
              </div>
            </div>

            <div className="modal-footer">
              <div className="left-side">
                {/* <Button
                  className="btn-link"
                  color="default"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.setState({ modalSurvey: !this.state.modalSurvey })}
                >
                  Senere
                </Button> */}
              </div>
              <div className="divider" />
              <div className="right-side">
                <Button
                  className="btn-link"
                  color="success"
                  type="button"
                  onClick={this.validateSurveySteps}
                >
                  {this.state.buttonText}
                </Button>
              </div>
            </div>
            {/* <div className="right-side">
                <Button
                  className="btn-link"
                  color="success"
                  type="button"
                  onClick={this.submitSurvey}
                >
                  Send tilbakemelding
                </Button>
              </div>
            </div> */}
          </Modal>

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Oversikt rørleggerarbeider</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.customerJobArray}
                    expanded={this.state.expanded}
                    filterable
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    previousText={"Forrige"}
                    nextText={"Neste"}
                    pageText={"Side"}
                    ofText={"av"}
                    rowsText={"rader"}
                    noDataText={<b>Ingen jobber funnet</b>}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight"
                    columns={[
                      // {
                      //   Header: "Spørreskjema",
                      //   accessor: "surveyCompleted",
                      //   filterable: false,
                      //   headerStyle: { textAlign: 'left' }
                      // },
                      {
                        Header: "Jobb dato",
                        accessor: "date",
                        filterable: false
                      },
                      {
                        Header: "Jobb nummer",
                        accessor: "jobId",
                        filterable: false,
                        headerStyle: { textAlign: 'left' }
                      },
                      // {
                      //   Header: "Fakturanummer",
                      //   accessor: "invoice",
                      //   filterable: false,
                      //   headerStyle: { textAlign: 'left' }
                      // },

                    ]}

                    SubComponent={row => {
                      return (
                        <div style={{ padding: "20px" }}>
                          <ReactTable
                            minRows={1}
                            data={customerJobSchemasArray}
                            columns={[
                              {
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                width: 60,
                              },
                              {
                                Header: "Skjemanavn",
                                accessor: "col2",
                                filterable: false,
                                headerStyle: { textAlign: 'left' }
                              }
                            ]}
                            showPagination={false}
                          />
                        </div>
                      );
                    }}


                    getTrProps={(state, rowInfo, column, instance, expanded) => {
                      return {
                        onClick: e => {
                          if (this.state.rowexpanded === rowInfo.viewIndex) {
                            this.setState({ expanded: { [rowInfo.viewIndex]: false } });
                            this.setState({ rowexpanded: null });
                            this.setState({ customerJobSchemasArray: [] });
                          }
                          else {
                            this.getSchemas(rowInfo.row.jobId, rowInfo.row._original.invoice)
                            this.setState({ jobId: rowInfo.row.jobId });
                            this.setState({ expanded: { [rowInfo.viewIndex]: true } });
                            this.setState({ rowexpanded: rowInfo.viewIndex });
                            if (rowInfo.original.surveyCompleted === 'false') {
                              this.modalSurvey(rowInfo.original.jobId, rowInfo.original.employeeId)
                            }

                          }
                        }
                      };
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div >
      </>
    );
  }
}

export default ServiceJobs;


